import React from "react";
import { Layout } from "../Componentns/Layout";
import * as S from "./styles";
import SuccesIcon from "../Images/Flat_tick_icon.svg";

function ApplicationComplete({ applicantEmail }) {
    return (
        <Layout>            
            <S.CompleteContainer>                
                <S.CompleteInner>
                    <S.CompleteImage src={SuccesIcon} />
                    <div>
                        <h1>Your Application has been successfully submitted</h1>
                        <div>
                            A confirmation email has been sent to your email address provided. If you have any queries regarding your application, please contact the admissions team at SEAMEO Vietnam OR Curtin Colombo.
                        </div>
                    </div>
                </S.CompleteInner>
            </S.CompleteContainer>
            
        </Layout>
    );
}

export default ApplicationComplete;