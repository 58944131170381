import { Colour, Device, Text } from '@curtin-dxp/ui';
import { rem } from 'polished';
import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-areas:
    "header header"
    "splash splash"
    "controls controls"
    "sidebar content"
    "sidebar help"
    "sidebar footer";
  height: 100%;

  @media print {
    overflow: hidden;
    ::-webkit-scrollbar {
      display: none;
    }

    grid-template-areas:
    "header header"
    "controls controls"
    "content content";

    @page {
      size: A4 portrait;
    }
  }
`;

export const ContentContainer = styled.main`
  height: 100%;
  transition: padding 0.3s ease-in-out;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  align-self: flex-start;
  height: 5vh;
  width: 100%;
  max-width: 900px;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  @media ${Device.MobileLg} {
    margin: 0 ${rem(10)};
  }
`;

export const HeaderText = styled(Text)`
  color: ${Colour.secondary.monochromeShades.white};
  font-size: ${rem(20)};
`;

export const HeaderTextContainer = styled.div`
  background-color: ${Colour.secondary.monochromeShades.black};
  padding-left: ${rem(10)};
  display: block;
  width: 100%;
`;

export const ProgressContainer = styled.div`
  display: block;
`;