import React, { useEffect, useRef } from "react";
import * as S from "../../../Common/styles";
export const SLIITALEList = (props) => {
    const textboxRef = useRef(null);

    useEffect(() => {
      if (props.selected === "Other A Level (please specify)") {
        // Focus on the textbox when the "Other" option is selected
        textboxRef.current.focus();
      }
    }, [props.selected]);

    return (
        <S.RadioGroup>
            <label>
                <input className="form-check-input" type="radio" name={props.name} value="Cambridge GCE A Level" onChange={props.onChange} />
                Cambridge GCE A Level
            </label>
            <label>
                <input className="form-check-input" type="radio" name={props.name} value="Pearson Edexcel A Level" onChange={props.onChange} />
                Pearson Edexcel A Level
            </label>
            <label>
                <input className="form-check-input" type="radio" name={props.name} value="Sri Lanka GCE A Level" onChange={props.onChange} />
                Sri Lanka GCE A Level                
            </label>
            <label>
                <input className="form-check-input" type="radio" name={props.name} value="Other A Level (please specify)" onChange={props.onChange} />
                Other A Level (please specify)             
            </label>
            <S.Textbox ref={textboxRef} disabled={props.selected !== "Other A Level (please specify)"} id={props.sliitALEALevelOther} onChange={props.onChange} />
        </S.RadioGroup>
    );
}