import { FormRow, Button } from "@curtin-dxp/ui";
import * as CS from "../../Common/styles";
import { CourseYearSelect } from "./CourseYearSelect";
import { CourseSelect } from "./CourseSelect";
import { CourseSpecialization } from "./CourseSpecialization";
import { SemesterSelect } from "./SemesterSelect";
import { YesNoSelect } from "../../Common/YesNoSelect";
import { SLIITAListScienceSubjects } from "./SLIITAListScienceSubjects";
import { SLIITALEList } from "./SLIITALEList";
import * as S from "./styles";

export const CourseSelection = (props) => {    
    const sliitSpecializationCourses = ["Bachelor of Engineering (BH-ENGR)", "Bachelor of Computing (B-COMP)"];

    return (
        <>
            <CS.Section style={({"overflow":"hidden"})}>
                <CS.H3>Select your study location. *</CS.H3>
                <CS.CentredFormRow>
                    <CS.ButtonMR id="btnSelectSLIIT" variant={`${props.campus === 'SLIIT' ? 'primary' : 'secondary'}`} text="Curtin Colombo (Sri Lanka)" onClick={() => props.handleCampusSelectionChanged("SLIIT")} />
                    <Button id="btnSelectSEAMEO" variant={`${props.campus === 'SEAMEO' ? 'primary' : 'secondary'}`} text="SEAMEO (Vietnam)" onClick={() => props.handleCampusSelectionChanged("SEAMEO")} />                
                </CS.CentredFormRow>
                <S.OtherCampus>
                    <a href="https://www.curtin.edu.au/study/">Click here if other campus</a>
                </S.OtherCampus>                
            </CS.Section>
            {props.campus === 'SEAMEO' && (
                <>                
                    <CS.Section>
                        <CS.H3>MC-APLING : Master of Arts (Applied Linguistics)</CS.H3>
                        <FormRow>
                            <CourseYearSelect id="seameoCourseYear" label="Select Commencement Year" handleSelection={props.handleSelection} selection={props.seameoCourseYear} required />                        
                            {/* <TrimesterSelect id="seameoTrimester" label="Select Study Period" handleSelection={props.handleSelection} selection={props.seameoTrimester} required /> */}
                        </FormRow>
                    </CS.Section>
                    <CS.Section>
                        <CS.H3>Please submit the following documents with your application:</CS.H3>
                        <CS.NumberedUL>
                            <li>
                                Transcripts
                            </li>
                            <li>
                                CV/Resume
                            </li>
                            <li>
                                Letter from employer - Position title, duration and job-description to validate work experience
                            </li>
                        </CS.NumberedUL>
                    </CS.Section>
                </>
            )}
            {props.campus === "SLIIT" && ( 
                <>
                    <CS.Section>
                        <CS.H3>Select your course and specialisation</CS.H3>
                        <FormRow>
                            <CourseSelect id="sliitCourse" label="Course" selection={props.sliitCourse} handleSelection={props.handleSelection} required />
                            {props.sliitCourse !== '' && sliitSpecializationCourses.includes(props.sliitCourse) && (
                                <CourseSpecialization id="sliitSpecialization" label="Specialisation / Major" handleSelection={props.handleSelection} course={props.sliitCourse} selection={props.sliitSpecialization} required />
                            )}                                
                        </FormRow>
                    </CS.Section>                
                    {(props.sliitCourse !== "Bachelor of Business Administration (B-BUSADM)" && props.sliitCourse !== '') && (
                        <>                            
                            <CS.Section>
                                <CS.H3>Are you taking the Advanced Level examination? *</CS.H3>
                                <CS.CentredFormRow>
                                    <CS.ButtonMR text="Yes" variant={props.sliitALE === "Yes" ? "primary" : "secondary"} onClick={() => props.handleSLIITALEChanged("Yes")} />
                                    <Button text="No" variant={props.sliitALE === "No" ? "primary" : "secondary"} onClick={() => props.handleSLIITALEChanged("No")} />
                                </CS.CentredFormRow>
                            </CS.Section>
                            {props.sliitALE === "Yes" && (
                                <CS.Section>
                                    <CS.H3>Please specify the type of A Level from the list below *</CS.H3>
                                    <FormRow>
                                        <SLIITALEList name="sliitALEALevel" selected={props.sliitALEALevel} onChange={props.onChange} sliitALEALevelOther={props.sliitALEALevelOther} required />
                                    </FormRow>
                                    <CS.H3>Please select what science subject(s) you are studying on A Level *</CS.H3>
                                    <FormRow>
                                        <SLIITAListScienceSubjects name="sliitALEALevelSubject" onChange={props.onChange} required />
                                    </FormRow>
                                </CS.Section>
                            )}
                            {props.sliitALE === "No" && (
                                <CS.Section>
                                    <CS.H3>Please specify the qualification you are studying *</CS.H3>
                                    <FormRow>
                                        <CS.TextArea id="sliitALEOtherQualStudying" rows="4" onChange={props.onChange} value={props.sliitALEOtherQualStudying} />
                                    </FormRow>
                                </CS.Section>
                            )}
                        </>
                    )}                
                    <CS.Section>
                        <CS.H3>Preferred Course Commencement</CS.H3>
                        <CourseYearSelect id="sliitCourseYear" label="Select Commencement Year" handleSelection={props.handleSelection} selection={props.sliitCourseYear} required />
                        <SemesterSelect id="sliitSemester" label="Select Study Period" handleSelection={props.handleSelection} selection={props.sliitSemester} required />
                    </CS.Section>
                    <CS.Section>
                        <CS.H3>SLIIT Academy Curtin Foundation Program</CS.H3>
                        <FormRow>
                            <YesNoSelect id="sliitACFP" label="Are you coming from the SLIIT Academy Curtin Foundation Program?" handleSelection={props.handleSelection} selection={props.sliitACFP} required />
                        </FormRow>
                    </CS.Section>                
                </>
            )}
        </>
    );
}