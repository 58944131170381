import { FormRow } from "@curtin-dxp/ui";
import { CountrySelect, LanguageSelect } from "../../Common";
import * as CS from "../../Common/styles";

export const CulturalDetails = (props) => {
    return (
        <CS.Section>
            <CS.H3>Cultural Details</CS.H3>
            <p>
                Please provide your cultural details including the country in which you were born, and the main language spoken at home.
            </p>
            <FormRow>
                <CountrySelect id="countryOfBirth" label="Country of Birth" handleSelection={props.handleSelection} selection={props.countryOfBirth} required />
            </FormRow>
            <FormRow>
                <LanguageSelect id="mainLanguageHome" label="Main Language spoken at home" handleSelection={props.handleSelection} selection={props.mainLanguageHome} required />
            </FormRow>
        </CS.Section>
    );
}