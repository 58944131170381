import styled from "styled-components";
import { Colour } from "@curtin-dxp/ui";

export const ProgressTrackerContainer = styled.div`
    width: 100%;
    display: flex;
    margin: 0;
    padding: 1rem;
    align-items: center;
    justify-content: center;

    background-color: gray;
`;

export const ProgressItem = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${Colour.secondary.monochromeShades.white};
    padding: 0 1rem;
`;

export const ProgressItemLabel = styled.h3`
    font-weight: ${({currentstep}) => (currentstep === "true" ? 'bold' : 'noraml') };
    font-size: 1.3rem;
`;

export const ProgressSep = styled.div`
    content: "";
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 8px solid white;
    margin: 0 8px;
`;