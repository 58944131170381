import styled from "styled-components";
import { Colour } from "@curtin-dxp/ui";

export const infoPanel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid ${Colour.secondary.monochromeShades["grey-07"]};
    padding: 2rem;
    margin: 2rem auto;
    text-align: center;
    background-color: ${Colour.secondary.monochromeShades.white};
`

export const cmdBar = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: right;
    padding-righ: 2rem;
`;

export const spanRed = styled.span`
    font-size: 1.2rem;
    color: ${Colour.tertiary.chilli[400]};
    margin-top: 1rem;
`;

export const validationErrors = styled.div`
    color: ${Colour.tertiary.chilli[400]};
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 1rem 2rem 0 2rem;
    font-size: 1.3rem;

    &label {
        display: block;
        font-weight: bold;
    }

    &span {
        display: block;
    }
`;

export const Strong = styled.strong`
    font-weight: 600;
`;