import { Dropdown } from "@curtin-dxp/ui";

export const CourseSelect = (props) => {
    return (
        <Dropdown id={props.id} label={props.label} handleSelection={(e) => props.handleSelection(e, props.id)} selection={({value:props.selection})} options={Courses(props.id)} required={props.id} />
    )
}

function Courses(id) {
    const courses = ["Bachelor of Engineering (BH-ENGR)", "Bachelor of Science (B-SCCSNT)", "Bachelor of IT (B-INFTEC)", "Bachelor of Computing (B-COMP)", "Bachelor of Business Administration (B-BUSADM)"];
    
    return (
        courses.map((option) => (
            {
                value: option
            }
        ))
    );
}