import styled from "styled-components"
import { rem } from "polished";
import { TableData, Colour } from "@curtin-dxp/ui";

export const ContentContainer = styled.div`
    width: 80%;
    max-width: 900px;
    display: table;
    margin-left: auto;
    margin-right: auto;
`;

export const H1 = styled.h1`
    font-weight: 600;
`;

export const ContentItem = styled.div`
    margin: 2rem 0;
`;

export const Checkbox = styled.label`
    margin: 10px 0;

    &:hover {
        cursor: pointer;
    }

    & input[type=checkbox] {
        margin-right: 1rem;
    }
`;

export const Row = styled.div`
    display: table-row;
`;

export const Heading = styled(TableData)`
    display: table-cell;
`;

export const Value = styled(TableData)`
    display: table-cell;
`;

export const CommandBar = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Strong = styled.strong`
    margin: ${rem(1.5)} 0;
    font-weight: 600;
`;

export const infoPanel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid ${Colour.secondary.monochromeShades["grey-00"]};
    padding: 2rem;
    margin: 2rem auto;
    text-align: center;
`

export const cmdBar = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: right;
    padding-righ: 2rem;
`;

export const spanRed = styled.span`
    color: ${Colour.tertiary.chilli[400]};
`;

export const validationErrors = styled.div`
    color: ${Colour.tertiary.chilli[400]};
    display: flex;
    flex-direction: column;
    text-align: left;

    & label {
        display: block;
        font-weight: bold;
    }

    & span {
        display: block;
    }
`;

export const FormData = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LinkButton = styled.button`
    color: ${Colour.primary.deepblue[400]};
    border: none;
    background-color: inherit;
    margin: 0;
    padding: 0;
`;