import React from "react";
import { Dropdown } from "@curtin-dxp/ui";

export const DOBSelect = (props) => {
    return (
        <>        
            <Dropdown id="dobDay" label={props.label} handleSelection={(e) => props.handleSelection(e, "dobDay")} options={days()} selection={({value:props.dobDay})} required={props.required} />
            <Dropdown id="dobMonth" handleSelection={(e) => props.handleDOBMonthSelect(e)} options={months()} selection={({id:props.dobMonth, value:props.dobMonthName})} />
            <Dropdown id="dobYear" handleSelection={(e) => props.handleSelection(e, "dobYear")} options={years()} selection={({value:props.dobYear})} />            
        </>
    );
}

const days = () => {
    const days = Array.from({ length: 31 }, (_, index) => index + 1);
    
    return (
        days.map((day) => (
            {
                value: day.toString()
            }
        ))
    );
}

const months = () => {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];

      return (
        months.map((month, index) => (
            {
                id: (index + 1).toString().length === 2 ? (index + 1).toString() : `0${(index + 1).toString()}`,
                value: month
            }
        ))
      );
}

const years = () => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 15;
    const endYear = currentYear - 65;
  
    const yearsArray = Array.from({ length: startYear - endYear + 1 }, (_, index) => startYear - index);
  
    return yearsArray.map((year) => (
        {
            value: year.toString(),
        }
        )
    );
  }