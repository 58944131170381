import * as S from "../../../Common/styles";

export const SLIITAListScienceSubjects = (props) => {
    return (
        <S.RadioGroup>
            <label>
                <input name={props.name} className="form-check-input" type="radio" onChange={(e) => props.onChange(e)} value="Physics" />
                Physics
            </label>
            <label>
                <input name={props.name} className="form-check-input" type="radio" onChange={(e) => props.onChange(e)} value="Chemistry" />
                Chemistry
            </label>
            <label>
                <input name={props.name} className="form-check-input" type="radio" onChange={(e) => props.onChange(e)} value="Physics and Chemistry" />
                Physics and Chemistry
            </label>            
        </S.RadioGroup>
    );    
}