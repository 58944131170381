import { Dropdown } from "@curtin-dxp/ui";

export const CourseSpecialization = (props) => {
    return (
        <Dropdown id={props.id} label={props.label} handleSelection={(e) => props.handleSelection(e, props.id)} selection={({value:props.selection})} options={Specializations(props.course)} required={props.required} />
    );
}

function Specializations(course) {
    var options = [];

    switch (course) {
        case "Bachelor of Engineering (BH-ENGR)":
            options = ["Civil and Construction Engineering(Hons)", "Electrical and Electronic Engineering(Hons)", "Mechanical Engineering(Hons)", "Mechatronic Engineering(Hons)"];
            break;

        case "Bachelor of Computing (B-COMP)":
            options = ["Cyber Security", "Software Engineering"];
            break;

        default:
            return (null);
    }

    return (
        options?.map((option) => (
            {
                value: option
            }
        ))
    );
}