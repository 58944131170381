import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Table, TableRow, TableData, Checkbox, Button, TableBody } from "@curtin-dxp/ui";
import ApplicationModel from "../../Models/ApplicationModel";
import * as S from "./styles";
import * as CS from "../Common/styles";
import { Layout } from "../Layout";
import { SubmitApplication } from "../../Services/CRMSubmit";
import { TermsOfApplication } from "./TermsOfApplication";

export const SubmitApplicationComponent = (e) => {
    const navigate = useNavigate();
    const location = useLocation();
    const inputData = new ApplicationModel();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const scrollToTopRef = useRef(document.documentElement);
    const [acceptedAD, setAcceptedAD] = useState(false);
    const [modalTitle, setModalTitle] = useState();
    const [modalContent, setModalContent] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const [submitError, setSubmitError] = useState();
    const [captchaLoading, setCaptchaLoading] = useState(true);
    const [renderCaptchaFailed, setRenderCaptchaFailed] = useState(false);
    const [captchaCompleted, setCaptchaComplete] = useState(false);
    if (location.state && location.state.inputData) Object.assign(inputData, location.state.inputData);

    useEffect(() => {      
        if (captchaLoading){
            const scriptExists = Array.from(document.scripts).some((script) =>
                script.src === process.env.REACT_APP_CAPTCHA_API_URL
            );

            if (scriptExists) return;

            scrollToTop();
            
            const script = document.createElement('script');
            script.src = process.env.REACT_APP_CAPTCHA_API_URL;
            script.async = true;
        
            script.addEventListener('load', () => {            
                const container = document.getElementById('captcha-container');
                window.AwsWafCaptcha.renderCaptcha(container, {
                    apiKey: process.env.REACT_APP_CAPTCHA_API_KEY,
                    onSuccess: () => { 
                        setCaptchaComplete(true);
                    },
                    onError: () => {
                    }
                })
                .catch(() => {
                    setSubmitError("Failed to render CAPTCHA. Form cannot be submitted.");
                    setRenderCaptchaFailed(true);
                    scrollToTop();
                });                            
            });

            setCaptchaLoading(false);

            document.body.appendChild(script);
        }        
      }, [captchaLoading, captchaCompleted]);
    
    async function handleSubmit(e) {
        e.preventDefault();

        setIsFormSubmitted(true);

        if (!captchaCompleted){
            setSubmitError("You must complete the CAPTCHA puzzle to continue.");
            scrollToTop();
            return;            
        }        

        if (!formIsValid()) {
            scrollToTop();
            return;
        }

        const submitSuccess = await SubmitApplication(inputData);

        if (submitSuccess === true){
            navigate('/ApplicationComplete');
            return;
        }

        setSubmitError("Failed to submit your application. An error occurred.");
        scrollToTop();
    }

    const handleBack = (event) => {
        navigate('/ProvideDetails', { state: {inputData}});
    }

    function handleAcceptance(e) {
        setAcceptedAD(e.target.checked);
    }

    function handleShowModal(e){
        e.preventDefault();

        document.body.style.overflow = 'hidden';
        setModalTitle("Terms of Application");
        setModalContent(<TermsOfApplication />);
        setModalVisible(true);
    }

    function handleCloseModal(e){
        e.preventDefault();

        document.body.style.overflow = 'auto';
        setModalVisible(false);
    }

    const scrollToTop = () => {
        scrollToTopRef.current.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    function formIsValid() {
        return validateForm() === null;
    }

    function validateForm() {
        const validationErrors = [];

        if (!acceptedAD) validationErrors.push("*You must accept the Applicant Declaration to continue");

        return (
            validationErrors.length === 0 ? null : 
            <>
                <b>Please correct the following erros:</b>
                {validationErrors.map((value) => (
                    <span>{value}</span>
                ))}
            </>
        );        
    }

    return (
        <Layout>
            <CS.ContentContaienr>
                {renderCaptchaFailed && (
                         <>
                         <CS.AlertNotification variant="alert" icon="alert" style={({"display":"inline-flex"})} text="Failed to render CAPTCHA. Form cannot be submitted." />
                         <br />
                     </>               
                )}

                {submitError && (
                    <>
                        <CS.AlertNotification variant="alert" icon="alert" style={({"display":"inline-flex"})} text={submitError} />
                        <br />
                    </>                    
                )}

                {isFormSubmitted && !formIsValid() && (
                    <>
                        <CS.AlertNotification variant="alert" icon="alert" style={({"display":"inline-flex"})} text="You must select the Terms of Application to continue" />
                        <br />
                    </>
                )}

                <CS.Notification text="Please review your details below, accept the Terms of Application, then click Submit." />   

                <S.ContentItem>
                    <S.H1>Applicant Details</S.H1>
                    <CS.BasicContainer>
                        <Table style={({"marginTop":"0"})}>
                            <TableBody>
                                <TableRow>
                                    <S.Heading colSpan={2}>
                                        <CS.H4>Personal Details</CS.H4>
                                    </S.Heading>
                                </TableRow>
                                {inputData.title?.trim() !== '' && (
                                    <TableRow>
                                        <S.Heading>
                                            Title
                                        </S.Heading>
                                        <S.Value>
                                            {inputData.title}
                                        </S.Value>
                                    </TableRow>
                                )}
                                {inputData.firstName?.trim() !== '' && (
                                    <TableRow>
                                        <S.Heading>
                                            First Given Name
                                        </S.Heading>
                                        <S.Value>
                                            {inputData.firstName}
                                        </S.Value>
                                    </TableRow>
                                )}
                                {inputData.otherGivenNames !== '' && (
                                    <TableRow>
                                        <S.Heading>
                                            Other Given Names
                                        </S.Heading>
                                        <S.Value>
                                            {inputData.otherGivenNames}
                                        </S.Value> 
                                    </TableRow>                             
                                )}
                                {inputData.preferredGivenName !== '' && (
                                    <TableRow>
                                        <S.Heading>
                                            Preferred Given Name
                                        </S.Heading>
                                        <S.Value>
                                            {inputData.preferredGivenName}
                                        </S.Value> 
                                    </TableRow>                             
                                )}
                                <TableRow>
                                    <S.Heading>
                                        Family Name (Surname)
                                    </S.Heading>
                                    <S.Value>
                                        {inputData.familyName}
                                    </S.Value> 
                                </TableRow>
                                <TableRow>
                                    <S.Heading>
                                        Date of Birth
                                    </S.Heading>
                                    <S.Value>
                                        {inputData.dob}
                                    </S.Value> 
                                </TableRow>
                                <TableRow>
                                    <S.Heading>
                                        Gender
                                    </S.Heading>
                                    <S.Value>
                                        {inputData.gender}
                                    </S.Value> 
                                </TableRow>

                                <TableRow>
                                    <TableData></TableData>
                                </TableRow>

                                <TableRow>
                                    <S.Heading colSpan={2}>
                                        <CS.H4>Email &amp; Phone Details</CS.H4>
                                    </S.Heading>
                                </TableRow>
                                <TableRow>
                                    <S.Heading>
                                        Email Address
                                    </S.Heading>
                                    <S.Value>
                                        {inputData.confirmEmailAddress}
                                    </S.Value>
                                </TableRow>
                                <TableRow>
                                    <S.Heading>
                                        Phone Number
                                    </S.Heading>
                                    <S.Value>
                                        {inputData.phoneNumber}
                                    </S.Value> 
                                </TableRow>

                                <TableRow>
                                    <TableData></TableData>
                                </TableRow>

                                <TableRow>
                                    <S.Heading colSpan={2}>
                                        <CS.H4>Address Details</CS.H4>
                                    </S.Heading>
                                </TableRow>
                                <TableRow>
                                    <S.Heading>
                                    Country
                                    </S.Heading>
                                    <S.Value>
                                    {inputData.addressCountry}
                                    </S.Value>
                                </TableRow>
                                <TableRow>
                                    <S.Heading>
                                    Address Line 1
                                    </S.Heading>
                                    <S.Value>
                                    {inputData.addressLine1}
                                    </S.Value>
                                </TableRow>
                                {inputData.addressLine2 !== '' && (
                                    <TableRow>
                                        <S.Heading>
                                        Address Line 2
                                        </S.Heading>
                                        <S.Value>
                                        {inputData.addressLine2}
                                        </S.Value>
                                    </TableRow>
                                )}
                                {inputData.addressLine3 !== '' && (
                                    <TableRow>
                                        <S.Heading>
                                        Address Line 3
                                        </S.Heading>
                                        <S.Value>
                                        {inputData.addressLine3}
                                        </S.Value>
                                    </TableRow>
                                )}
                                <TableRow>
                                    <S.Heading>
                                    Suburb/Town
                                    </S.Heading>
                                    <S.Value>
                                    {inputData.addressSuburbTown}
                                    </S.Value>
                                </TableRow>
                                {inputData.addressState?.trim() !== '' && (                        
                                    <TableRow>
                                        <S.Heading>
                                        State
                                        </S.Heading>
                                        <S.Value>
                                        {inputData.addressState}
                                        </S.Value>
                                    </TableRow>   
                                )}
                                {inputData.addressPostcode?.trim() !== '' && (                                                                                                                                                                                               
                                    <TableRow>
                                        <S.Heading>
                                        Postcode
                                        </S.Heading>
                                        <S.Value>
                                        {inputData.addressPostcode}
                                        </S.Value>
                                    </TableRow>       
                                )}

                                <TableRow>
                                    <TableData></TableData>
                                </TableRow>

                                <TableRow>
                                    <S.Heading colSpan={2}>
                                        <CS.H4>Citizenship Details</CS.H4>
                                    </S.Heading>
                                </TableRow>
                                <TableRow>
                                    <S.Heading>
                                        Country of citizenship
                                    </S.Heading>
                                    <S.Value>
                                        {inputData.countryOfCitizenship}
                                    </S.Value>
                                </TableRow>

                                <TableRow>
                                    <TableData></TableData>
                                </TableRow>

                                <TableRow>
                                    <S.Heading colSpan={2}>
                                        <CS.H4>Cultural Details</CS.H4>
                                    </S.Heading>
                                </TableRow>
                                <TableRow>
                                    <S.Heading>
                                        Country of Birth
                                    </S.Heading>
                                    <S.Value>
                                        {inputData.countryOfBirth}
                                    </S.Value>
                                </TableRow>
                                <TableRow>
                                    <S.Heading>
                                        Main Language spoken at home
                                    </S.Heading>
                                    <S.Value>
                                        {inputData.mainLanguageHome}
                                    </S.Value>
                                </TableRow>

                                <TableRow>
                                    <TableData></TableData>
                                </TableRow>

                                <TableRow>
                                    <S.Heading colSpan={2}>
                                        <CS.H4>Course Details</CS.H4>
                                    </S.Heading>
                                </TableRow>
                                <TableRow>
                                    <TableData>
                                        Study Location
                                    </TableData>
                                    <TableData>
                                        {inputData.campus === "SLIIT" ? "Curtin Colombo (Sri Lanka)" : "SEAMEO (Vietnam)"}
                                    </TableData>
                                </TableRow>
                                {inputData.campus === "SEAMEO" && (
                                    <>
                                        <TableRow>
                                            <TableData>
                                                Commencement Year
                                            </TableData>
                                            <TableData>
                                                {inputData.seameoCourseYear}
                                            </TableData>
                                        </TableRow>
                                        <TableRow>
                                            <TableData>
                                                Study Period
                                            </TableData>
                                            <TableData>
                                                {inputData.seameoTrimester}
                                            </TableData>                                    
                                        </TableRow>
                                    </>
                                )}
                                {inputData.campus === "SLIIT" && (
                                    <>
                                        <TableRow>
                                            <TableData>
                                                Course
                                            </TableData>
                                            <TableData>
                                                {inputData.sliitCourse}
                                            </TableData>
                                        </TableRow>
                                        {inputData.sliitSpecialization !== '' && (
                                            <TableRow>
                                                <TableData>
                                                    Specialization
                                                </TableData>
                                                <TableData>
                                                    {inputData.sliitSpecialization}
                                                </TableData>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableData>
                                                Commencement Year
                                            </TableData>
                                            <TableData>
                                                {inputData.sliitCourseYear}
                                            </TableData>
                                        </TableRow>
                                        <TableRow>
                                            <TableData>
                                                Study Period
                                            </TableData>
                                            <TableData>
                                                {inputData.sliitSemester}
                                            </TableData>
                                        </TableRow>                                    
                                        <TableRow>
                                            <TableData>
                                                Advanced Level Examination
                                            </TableData>
                                            <TableData>
                                                {inputData.sliitALE}
                                            </TableData>
                                        </TableRow>
                                        {inputData.sliitALE === "Yes" && (
                                            <>
                                                <TableRow>
                                                    <TableData>
                                                        A Level Type
                                                    </TableData>
                                                    <TableData>
                                                        {inputData.sliitALEALevel}
                                                    </TableData>
                                                </TableRow>
                                                <TableRow>
                                                    <TableData>
                                                        Science subject(s)
                                                    </TableData>
                                                    <TableData>
                                                        {inputData.sliitALEALevelSubject}
                                                    </TableData>
                                                </TableRow>                                            
                                            </>
                                        )}
                                        {inputData.sliitALE === "No" && (
                                            <TableRow>
                                                <TableData>
                                                    Qualification you are studying
                                                </TableData>
                                                <TableData>
                                                    {inputData.sliitALEOtherQualStudying}
                                                </TableData>
                                            </TableRow>
                                        )}
                                    </>
                                )}
                            
                                {inputData.additionalComments !== '' && (
                                    <>
                                        <TableRow>
                                            <TableData></TableData>
                                        </TableRow>                                                                                                
                                        <TableRow>
                                            <S.Heading>
                                                Additional Comments
                                            </S.Heading>
                                            <S.Value>
                                                {inputData.additionalComments}
                                            </S.Value>
                                        </TableRow>
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </CS.BasicContainer>                
                </S.ContentItem>

                <S.ContentItem>
                    <CS.H3>Terms of Application</CS.H3>
                    <S.FormData>
                        <p>You must agree to the Terms of Application including Applicant Declaration to continue with your application.</p>
                        <S.Checkbox>
                            <Checkbox onChange={handleAcceptance} />
                            I agree to the <S.LinkButton onClick={handleShowModal}>Terms of Application</S.LinkButton>
                        </S.Checkbox>
                    </S.FormData>
                </S.ContentItem>

                <S.ContentItem>
                    <CS.BasicContainer id="captcha-container" style={({"paddingTop":"2rem"})} />
                </S.ContentItem>


                <S.CommandBar>
                    <Button text="Back" variant="secondary" onClick={handleBack} />
                    <Button text="Submit" disabled={renderCaptchaFailed} variant="primary" onClick={handleSubmit} />
                </S.CommandBar>

                <CS.AppModal id="modalCtrl" show={modalVisible} handleClose={handleCloseModal} closeOnClick={handleCloseModal}>
                    <CS.ModalPopup title={modalTitle} closeOnClick={handleCloseModal}>
                        <CS.ModalContentContainer>
                            {modalContent}                                
                        </CS.ModalContentContainer>
                    </CS.ModalPopup>
                </CS.AppModal>
            </CS.ContentContaienr>
        </Layout>
    );
}