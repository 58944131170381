import { Dropdown } from "@curtin-dxp/ui";

export const SemesterSelect = (props) => {
    return (
        <Dropdown id={props.id} label={props.label} handleSelection={(e) => props.handleSelection(e, props.id)} options={Semesters()} selection={({value:props.selection})} required={props.required} />
    );
}

function Semesters() {
    const semesterArray = ["Semester-1", "Semester-2"];

    return (
        semesterArray.map((semester) => (
            {
                value: semester
            }
        ))
    );
}