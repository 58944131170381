import { Button, Colour, FormRow, Modal, Popup, TextInput, Notice } from '@curtin-dxp/ui';
import { styled } from 'styled-components';
import { rem } from 'polished';

export const StaggeredOnView = (time = 0.3) => ({
    initial: 'hidden',
    animate: 'show',
    whileInView: 'show',
    exit: 'exit',
    viewport: {
      once: true,
    },
    transition: {
      staggerChildren: time,
    },
  });
  
  export const Item = {
    hidden: { opacity: 0 },
    exit: { opacity: 0 },
    show: { opacity: 1 },
  };

  export const AppArticle = styled.div`

  `;

  export const AppArticleHeading = styled.h1`
    text-align: ${({align}) => (align)};
    font-weight: 600;
  `;

  export const CentredFormRow = styled(FormRow)`
    display: flex;
    alighn-items: center;
    justify-content: center;
    margin-top: 1.5rem;
  `;

  export const ButtonMR = styled(Button)`
    margin-right: ${rem(1.2)};
  `;

  export const RadioGroup = styled.div`
    display: flex;
    flex-direction: column;

    & input[type=radio] {
      margin-right: 10px;
    }

    & label:hover {
      cursor: pointer;
    }
  `;
  
  export const Strong = styled.strong`
    font-weight: 600;
    display: block;
  `;

  export const TextArea = styled.textarea`
    width: 100%;
    border: 2px solid #E8E8E8;
`;

export const Section = styled.div`
    margin-bottom: 3rem;
    border: 1px solid ${Colour.secondary.monochromeShades['grey-07']};
    padding: 2rem;
    background-color: ${Colour.secondary.monochromeShades.white};
`;

export const UploadsSection = styled.div`
    margin-bottom: 3rem;
    border: 1px solid ${Colour.secondary.monochromeShades['grey-07']};
    padding: 2rem;
    background-color: ${Colour.secondary.monochromeShades.white};

    & :last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
`;

export const AppModal = styled(Modal)`

`;

export const ModalPopup = styled(Popup)`
    width: 80%;
    max-width: none;
    max-height: 90vh;
`;

export const ModalContentContainer = styled.div`
    padding: 1.5rem;
    padding-bottom: 3rem;
    font-size: 1.3rem;
    max-height: 85vh;
    overflow-y: auto;
`;

export const Textbox = styled(TextInput)`

    & label {
      display: inline-flex;
      background-color: pink;
    }

    & input[type=text]{
      display: inline-flex;
    }
`;

export const H3 = styled.h3`
    font-weight: 700;
    font-family: effra,proxima-nova,Helvetica,Arial,sans-serif;
`;

export const H4 = styled.h4`
    font-weight: 600;
`;

export const Notification = styled(Notice)`
    margin: 2rem 0 0 0;
    background-color: #fef3cd;
    border-bottom: 0.5rem solid #ffbc26;
    display: inline-flex;
    padding: 16px 16px;
    font-size: 1.6rem;
    font-family: proxima-nova,Helvetica,Arial,sans-serif;
`;

export const AlertNotification = styled(Notice)`
    margin: 2rem 0 0 0;
    padding: 16px 16px;
    font-size: 1.6rem;
    font-family: proxima-nova,Helvetica,Arial,sans-serif;    
    background-color: #ffc5c4;
    border-bottom: 0.5rem solid #ff615e;
`;

export const InfoPoints = styled.ul`
    list-style: initial;
    text-align: left;
    font-size: 1rem;
`;

export const UL = styled.ul`
    list-style: initial;
    margin: 0.75rem 1.5rem 1.5rem 1.5rem;
`;

export const ULDots = styled.ul`
    list-style: circle;
    margin: 0 2rem;
`;

export const BasicContainer = styled.div`
  border: 1px solid ${Colour.secondary.monochromeShades['grey-07']};
  padding: 0 2rem 2rem 2rem;
  background-color: ${Colour.secondary.monochromeShades.white};
  width: 100%;
  margin: 2rem 0;
  display: block;
`;

export const CenteredH1 = styled.h1`
    font-weight: 400;
    text-align: center;
    margin-below: 1rem;
`;

export const NumberedUL = styled.ul`
    list-style: decimal;
    margin: 0 2rem;
`;

export const ContentContaienr = styled.div`
    width: 80%;
    max-width: 1200px;
    margin-bottom: 2rem;
`;

export const InfoPanel = styled.div`
    max-width: 90%;
    padding: 1rem;
    margin-top: 1rem;
    font-style: italic;
    font-size: 1rem;
    border: 1px solid ${Colour.secondary.monochromeShades['grey-07']};
    display: table;
    margin-left: auto;
    margin-right: auto;
    background-color: #f7f7f7;    
`;

export const Bold = styled.span`
    font-weight: bold;
`;

export const Underline = styled.span`
    text-decoration: underline;
`;