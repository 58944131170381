import { CountrySelect } from "../../Common";
import { FormRow } from "@curtin-dxp/ui";
import * as CS from "../../Common/styles";

export const CitizenshipDetails = (props) => {
    return (
        <CS.Section>
            <CS.H3>Citizenship Details</CS.H3>
            <p>
                Please indicate your current citizenship or visa status.
            </p>
            <FormRow>
                <CountrySelect id="countryOfCitizenship" label="Country of citizenship" handleSelection={props.handleSelection} selection={props.countryOfCitizenship} required />
            </FormRow>
        </CS.Section>
    );
}