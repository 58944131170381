import { Device } from '@curtin-dxp/ui';
import React, { useEffect, useState } from 'react';
import { useIsClient, useIsomorphicLayoutEffect, useMediaQuery } from 'usehooks-ts';
import { Item, StaggeredOnView } from '../Common';
import * as S from './styles';

export const Header = () => {
    const [open, setOpen] = useState(false);
    const isClient = useIsClient();
    const isMobile = !useMediaQuery(Device.MobileLg);
    const show = isClient && isMobile ? open : true;
  
    const links = [
      {
        name: "CRICOS Provider Code 00301J"
      }
    ];
  
    const hamburgerOnClick = () => {
      setOpen(!open);
    };
  
    const closeHamburger = () => {
      setOpen(false);
    };
  
    useEffect(() => {
      if (!isMobile) {
        closeHamburger();
      }
    }, [isMobile]);
  
    useIsomorphicLayoutEffect(() => {
      document.body.style.overflow = open ? 'hidden' : 'auto';
    }, [open]);
  
    const icon = open ? 'times' : 'menu';

    return (
        <S.HeaderContainer $open={open}>
        <S.Container>
          <S.CurtinLogo variant="combined" href="https://curtin.edu.au" aria-label="Curtin University logo" />
  
          <S.HamburgerButton
            variant="icon"
            onClick={hamburgerOnClick}
            open={open}
            aria-label="Global navigation"
            id="global-hamburger-menu-button"
          >
            <S.HamburgerIcon src={icon} width={25} height={25} id="global-hamburger-menu" />
          </S.HamburgerButton>
        </S.Container>
        <S.Nav $show={show}>
          <S.UnorderedList variants={Item} {...StaggeredOnView(0.1)}>
            {links.map((link) => (
              <S.ListItem key={link.name} variants={Item}>
                <S.HeaderLink variant="quick" href={link.href} target="_blank" rel="noreferrer">
                  {link.name}
                </S.HeaderLink>
              </S.ListItem>
            ))}
          </S.UnorderedList>
        </S.Nav>
      </S.HeaderContainer>
    );
}