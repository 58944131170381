import React from 'react';
import { FormRow, TextInput } from '@curtin-dxp/ui';
import { DOBSelect, TitleSelect, GenderSelect } from '../../Common';
import * as CS from "../../Common/styles";
export const PersonalDetails = (props) => {
    const onInputChange = props.onChange;

    return (
    <CS.Section>
        <CS.H3>Personal Details</CS.H3>
        <p>
            Please provide your name, date of birth and gender as it appears on your passport or birth certificate. You must provide at least one name (First Given Name or Family Name (Surname)). If you only have one name, please provide it as your Family Name (Surname). 
        </p>
        <FormRow>
            <TitleSelect id="title" label="Title" handleSelection={props.handleSelection} selection={props.title} />
        </FormRow>
        <FormRow>            
            <TextInput id='firstName' label='First Given Name' onChange={onInputChange} value={props.firstName} maxLength={50} />
        </FormRow>
        <FormRow>
            <TextInput id="otherGivenNames" label="Other Given Names" onChange={onInputChange} value={props.otherGivenNames} maxLength={50} />
        </FormRow>
        <FormRow>
            <TextInput id="preferredGivenName" label="Preferred Given Name" onChange={onInputChange} value={props.preferredGivenName} maxLength={50} />
        </FormRow>
        <FormRow>
            <TextInput id="formalName" label="Formal Name" onChange={onInputChange} value={props.formalName} maxLength={100} />
        </FormRow>
        <FormRow>
            <TextInput id="familyName" label="Family Name (Surname)" onChange={onInputChange} value={props.familyName} required maxLength={50} />
        </FormRow>
        <FormRow style={({"align-items":"end"})}>
            <DOBSelect handleSelection={props.handleSelection} handleDOBMonthSelect={props.handleDOBMonthSelect} label="Date of Birth" dobDay={props.dobDay} dobMonth={props.dobMonth} dobMonthName={props.dobMonthName} dobYear={props.dobYear} required />
        </FormRow>
        <FormRow>
            <GenderSelect id="gender" label="Gender" handleSelection={props.handleSelection} selection={props.gender} required />
        </FormRow>
    </CS.Section>
    );
}