import React from 'react';
import * as S from './styles';

const legalLinks = [
  {
    name: 'Copyright',
    link: 'https://www.curtin.edu.au/copyright-statement/',
  },
  {
    name: 'Disclaimer',
    link: 'https://curtin.edu.au/disclaimer/',
  },
  {
    name: 'Privacy',
    link: 'https://informationmanagement.curtin.edu.au/privacy/',
  },
  {
    name: 'UGC Statement',
    link: 'https://www.curtin.edu.au/home/ugc-statement/',
  },
  {
    name: 'Popular Courses',
    link: 'https://www.curtin.edu.au/popular-links/#popular-courses',
  },
  {
    name: 'Popular Links',
    link: 'https://www.curtin.edu.au/popular-links/',
  },
  {
    name: 'Accessibility',
    link: 'https://curtin.edu.au/accessibility/',
  },
  {
    name: 'Contact',
    link: 'https://about.curtin.edu.au/contact/',
  },
];

export const Footer = () => <S.Footer legalLinks={legalLinks} />;

export default Footer;