import ApplicationModel from "../Models/ApplicationModel";
import { renderToString } from 'react-dom/server';

export async function SubmitApplication(data) {
    if (data === undefined) return (false);

    const inputData = new ApplicationModel();
    Object.assign(inputData, data); 
    
    inputData.message = await PrepareMessage(data);

    const jsonData = JSON.stringify(inputData);

    //Send application to API
    try {
        const response = await window.AwsWafIntegration.fetch('/formSubmit', {
            method: "POST",
            body: jsonData
        })
        
        return (response.status === 200);
    }
    catch (error) {
        return (false);
    }
}

async function PrepareMessage(data){
    if (data === undefined) return;

    const inputData = new ApplicationModel();

    Object.assign(inputData, data);    

    return renderToString(
        <table>
            <tbody>
                <tr>
                    <td colSpan={2}>
                        <h1>Application Details</h1>
                    </td>
                </tr>
                <tr>
                    <th colSpan="2">
                        <h3>Personal Details</h3>
                    </th>
                </tr>
                {inputData.title !== '' && (
                    <tr>
                        <th>
                            Title
                        </th>
                        <td>
                            {inputData.title}
                        </td>
                    </tr>                
                )}
                {inputData.firstName !== '' && (
                    <tr>
                        <th>
                            First Name
                        </th>
                        <td>
                            {inputData.firstName}
                        </td>
                    </tr>               
                )}
                {inputData.otherGivenNames !== '' && (
                    <tr>
                        <th>
                            Other Given Names
                        </th>
                        <td>
                            {inputData.otherGivenNames}
                        </td>
                    </tr>
                )}
                {inputData.preferredGivenName !== '' && (
                    <tr>
                        <th>
                            Preferred Given Name
                        </th>
                        <td>
                            {inputData.preferredGivenName}
                        </td>
                    </tr>
                )}                    
                {inputData.familyName !== '' && (
                    <tr>
                        <th>
                            Family Name
                        </th>
                        <td>
                            {inputData.familyName}
                        </td>
                    </tr>
                )}
                {inputData.dob !== '' && (
                    <tr>
                        <th>
                            Date of Birth
                        </th>
                        <td>
                            {inputData.dob}
                        </td>
                    </tr>
                )}
                {inputData.gender !== '' && (
                    <tr>
                        <th>
                            Gender
                        </th>
                        <td>
                            {inputData.gender}
                        </td>
                    </tr>
                )}
                {(inputData.emailAddress !== '' || inputData.phoneNumber !== '') && (
                    <>
                        {inputData.emailAddress !== '' && (
                            <tr>
                                <th>
                                    Email Address
                                </th>
                                <td>
                                    {inputData.emailAddress}
                                </td>
                            </tr>
                        )}
                        {inputData.phoneNumber !== '' && (
                            <tr>
                                <th>
                                    Phone Number
                                </th>
                                <td>
                                    {inputData.phoneNumber}
                                </td>
                            </tr>
                        )}
                    </>
                )}
                {(inputData.addressCountry !== '' || inputData.addressLine1 !== '' || inputData.addressSuburbTown !== '') && (
                    <>
                        <tr><td colSpan={2}>&nbsp;</td></tr>
                        <tr>
                            <th colSpan="2">
                                <h3>Address Details</h3>
                            </th>
                        </tr>
                        {inputData.addressCountry !== '' && (
                            <tr>
                                <th>
                                    Country
                                </th>
                                <td>
                                    {inputData.addressCountry}
                                </td>
                            </tr>
                        )}
                        {inputData.addressLine1 !== '' && (
                            <tr>
                                <th>
                                    Address Line 1
                                </th>
                                <td>
                                    {inputData.addressLine1}
                                </td>
                            </tr>
                        )}
                        {inputData.addressLine2 !== '' && (
                            <tr>
                                <th>
                                    Address Line 2
                                </th>
                                <td>
                                    {inputData.addressLine2}
                                </td>
                            </tr>
                        )}
                        {inputData.addressLine3 !== '' && (
                            <tr>
                                <th>
                                    Address Line 3
                                </th>
                                <td>
                                    {inputData.addressLine3}
                                </td>
                            </tr>
                        )}
                        {inputData.addressSuburbTown !== '' && (
                            <tr>
                                <th>
                                    Suburb/Town
                                </th>
                                <td>
                                    {inputData.addressSuburbTown}
                                </td>
                            </tr>
                        )}
                        {inputData.addressState !== '' && (
                            <tr>
                                <th>
                                    State
                                </th>
                                <td>
                                    {inputData.addressState}
                                </td>
                            </tr>
                        )}
                        {inputData.addressPostcode !== '' && (
                            <tr>
                                <th>
                                    Postcode
                                </th>
                                <td>
                                    {inputData.addressPostcode}
                                </td>
                            </tr>
                        )}
                    </>
                )}
                {inputData.countryOfCitizenship !== '' && (
                    <>
                        <tr><td colSpan={2}>&nbsp;</td></tr>
                        <tr>
                            <th colSpan="2">
                                <h3>Citizenship</h3>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                Country of Citizenship
                            </th>
                            <td>
                                {inputData.countryOfCitizenship}
                            </td>
                        </tr>
                    </>
                )}
                {(inputData.countryOfBirth !== '' || inputData.mainLanguageHome !== '') && (
                    <>
                        <tr><td colSpan={2}>&nbsp;</td></tr>
                        <tr>
                            <th colSpan="2">
                                <h3>Cultural Details</h3>
                            </th>                        
                        </tr>
                        {inputData.countryOfBirth !== '' && (
                            <tr>
                                <th>
                                    Country of Birth
                                </th>
                                <td>
                                    {inputData.countryOfBirth}
                                </td>
                            </tr>
                        )}
                        {inputData.mainLanguageHome !== '' && (
                            <tr>
                                <th>
                                    Main Language spoken at home
                                </th>
                                <td>
                                    {inputData.mainLanguageHome}
                                </td>
                            </tr>
                        )}
                    </>
                )}
                {inputData.campus !== '' && (
                    <>
                        <tr><td colSpan={2}>&nbsp;</td></tr>
                        <tr>
                            <th colSpan="2">
                                <h3>Course Details</h3>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                Campus
                            </th>
                            <td>
                                {inputData.campus}
                            </td>
                        </tr>
                        {inputData.campus === "SEAMEO" && (
                            <>
                                {inputData.seameoCourseYear !== '' && (
                                    <tr>
                                        <th>
                                            Commencement Year
                                        </th>
                                        <td>
                                            {inputData.seameoCourseYear}
                                        </td>
                                    </tr>
                                )}
                                {inputData.seameoTrimester !== '' && (
                                    <tr>
                                        <th>
                                            Study Period
                                        </th>
                                        <td>
                                            {inputData.seameoTrimester}
                                        </td>
                                    </tr>
                                )}
                            </>
                        )}
                        {inputData.campus === "SLIIT" && (
                            <>
                                {inputData.sliitCourse !== '' && (
                                    <tr>
                                        <th>
                                            Course
                                        </th>
                                        <td>
                                            {inputData.sliitCourse}
                                        </td>
                                    </tr>
                                )}
                                {inputData.sliitSpecialization !== '' && (
                                    <tr>
                                        <th>
                                            Specialization
                                        </th>
                                        <td>
                                            {inputData.sliitSpecialization}
                                        </td>
                                    </tr>
                                )}
                                {inputData.sliitCourseYear !== '' && (
                                    <tr>
                                        <th>
                                            Commencement Year
                                        </th>
                                        <td>
                                            {inputData.sliitCourseYear}
                                        </td>
                                    </tr>
                                )}
                                {inputData.sliitSemester !== '' && (
                                    <tr>
                                        <th>
                                            Study Period
                                        </th>
                                        <td>
                                            {inputData.sliitSemester}
                                        </td>
                                    </tr>
                                )}                      
                                {inputData.sliitALE !== '' && (
                                    <>
                                        <tr>
                                            <th>
                                                Taking the Advanced Level examination
                                            </th>
                                            <td>
                                                {inputData.sliitALE}
                                            </td>
                                        </tr>
                                        {inputData.sliitALE === "Yes" && (
                                            <>
                                                {inputData.sliitALEALevel !== '' && (
                                                    <tr>
                                                        <th>
                                                            A Level Type
                                                        </th>
                                                        <td>
                                                            {inputData.sliitALEALevel !== "Other A Level (please specify)" ? inputData.sliitALEALevel : `Other - ${inputData.sliitALEALevelOther}`}
                                                        </td>
                                                    </tr>
                                                )}
                                                {inputData.sliitALEALevelSubject !== '' && (
                                                    <tr>
                                                        <th>
                                                            A Level Science Subject(s)
                                                        </th>
                                                        <td>
                                                            {inputData.sliitALEALevelSubject}
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        )}
                                        {inputData.sliitALE === "No" && (
                                            <>
                                                {inputData.sliitALEOtherQualStudying !== '' && (
                                                    <tr>
                                                        <th>
                                                            Qualification Currently Studying
                                                        </th>
                                                        <td>
                                                            {inputData.sliitALEOtherQualStudying}
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                                {inputData.sliitACFP !== '' && (
                                    <tr>
                                        <th>
                                            SLIIT Academy Curtin Foundation Program
                                        </th>
                                        <td>
                                            {inputData.sliitACFP}
                                        </td>
                                    </tr>
                                )}
                            </>
                        )}
                    </>
                )}
                {((inputData.filePassportComments !== '' || inputData.fileEnglishProficiencyComments !== '' || inputData.fileAcademicTranscriptsComments !== '') || (inputData.campus === "SEAMEO" && (inputData.fileBachelorTranscriptsOrigLangComments !== '' || inputData.fileBachelorTranscriptsEnglishComments !== '' || inputData.fileAllDegreeAwardCertsOrigLangComments !== '' || inputData.fileAllDegreeAwardCertsEnglishComments !== '' || inputData.fileCVComments !== '' || inputData.fileEmploymentLettersComments !== '' || inputData.fileOtherSupportingMaterialComments !== ''))) && (
                    <>
                        <tr><td colSpan={2}>&nbsp;</td></tr>
                        <tr>
                            <th colSpan="2">
                                <h3>Uploaded Files - Additional Comments</h3>
                            </th>
                        </tr>                    
                    </>
                )}
                {(inputData.filePassportComments !== '' || inputData.fileEnglishProficiencyComments !== '' || inputData.fileAcademicTranscriptsComments !== '') && (
                    <>
                        {inputData.filePassportComments !== '' && (
                            <tr>
                                <th>
                                    Passport
                                </th>
                                <td>
                                    {inputData.filePassportComments}
                                </td>
                            </tr>
                        )}
                        {inputData.fileEnglishProficiencyComments !== '' && (
                            <tr>
                                <th>
                                    English Proficiency
                                </th>
                                <td>
                                    {inputData.fileEnglishProficiencyComments}
                                </td>
                            </tr>
                        )}
                        {inputData.fileAcademicTranscriptsComments !== '' && (
                            <tr>
                                <th>
                                    Academic Transcripts
                                </th>
                                <td>
                                    {inputData.fileAcademicTranscriptsComments}
                                </td>
                            </tr>
                        )}
                    </>
                )}
                {(inputData.campus === "SEAMEO" && (inputData.fileBachelorTranscriptsOrigLangComments !== '' || inputData.fileBachelorTranscriptsEnglishComments !== '' || inputData.fileAllDegreeAwardCertsOrigLangComments !== '' || inputData.fileAllDegreeAwardCertsEnglishComments !== '' || inputData.fileCVComments !== '' || inputData.fileEmploymentLettersComments !== '' || inputData.fileOtherSupportingMaterialComments !== '')) && (
                    <>
                        {inputData.fileBachelorTranscriptsOrigLangComments !== '' && (
                            <>
                                <tr>
                                    <th>
                                        Bachelor complete transcripts in original language
                                    </th>
                                    <td>
                                        {inputData.fileBachelorTranscriptsOrigLangComments}
                                    </td>
                                </tr>
                            </>
                        )}
                        {inputData.fileBachelorTranscriptsEnglishComments !== '' && (
                            <>
                                <tr>
                                    <th>
                                        Bachelor complete transcripts in English translation
                                    </th>
                                    <td>
                                        {inputData.fileBachelorTranscriptsEnglishComments}
                                    </td>
                                </tr>
                            </>
                        )}
                        {inputData.fileAllDegreeAwardCertsOrigLangComments !== '' && (
                            <>
                                <tr>
                                    <th>
                                        All degree award certificates in original language
                                    </th>
                                    <td>
                                        {inputData.fileAllDegreeAwardCertsOrigLangComments}
                                    </td>
                                </tr>
                            </>
                        )}
                        {inputData.fileAllDegreeAwardCertsEnglishComments !== '' && (
                            <>
                                <tr>
                                    <th>
                                        All degree award certificates in English translation
                                    </th>
                                    <td>
                                        {inputData.fileAllDegreeAwardCertsEnglishComments}
                                    </td>
                                </tr>
                            </>
                        )}
                        {inputData.fileCVComments !== '' && (
                            <>
                                <tr>
                                    <th>
                                        CV (Curriculum Vitae)
                                    </th>
                                    <td>
                                        {inputData.fileCVComments}
                                    </td>
                                </tr>
                            </>
                        )}
                        {inputData.fileEmploymentLettersComments !== '' && (
                            <>
                                <tr>
                                    <th>
                                        Employment Letter/s
                                    </th>
                                    <td>
                                        {inputData.fileEmploymentLettersComments}
                                    </td>
                                </tr>
                            </>
                        )}
                        {inputData.fileOtherSupportingMaterialComments !== '' && (
                            <>
                                <tr>
                                    <th>
                                        Other supporting material
                                    </th>
                                    <td>
                                        {inputData.fileOtherSupportingMaterialComments}
                                    </td>
                                </tr>
                            </>
                        )}
                    </>
                )}
                {inputData.additionalComments !== '' && (
                    <>
                        <tr><td colSpan={2}>&nbsp;</td></tr>
                        <tr>
                            <th colSpan="2">
                                Additinoal Comments
                            </th>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                {inputData.additionalComments}
                            </td>
                        </tr>                
                    </>
                )}
            </tbody>
        </table>
    );
}