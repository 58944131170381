import { Dropdown } from "@curtin-dxp/ui";

export const YesNoSelect = (props) => {
    return (
        <Dropdown id={props.id} label={props.label} handleSelection={(e) => props.handleSelection(e, props.id)} options={options()} selection={({value:props.selection})} required={props.required} />
    );
}

function options() {
    const optionsArray = ["Yes", "No"];

    return (
        optionsArray.map((option) => ({
            value: option
        }))
    );
} 