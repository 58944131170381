import React from 'react';
import { GlobalStyle, light, ThemeProvider, Modal } from '@curtin-dxp/ui';
import * as S from './styles';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { ProgressTracker } from '../ProgressTracker/ProgressTracker';

export const Layout = ({ children }) => {
  return (
      <ThemeProvider theme={light}>
        <GlobalStyle />
        <Header />        
        <S.HeaderTextContainer>
          <S.HeaderText variant="h3">Partner Offshore Application Form</S.HeaderText>
        </S.HeaderTextContainer>
        <ProgressTracker />
        <S.ContentContainer>                              
            {children}
        </S.ContentContainer>
        <Footer />
        
      </ThemeProvider>
  );
};