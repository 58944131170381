import { Dropdown } from "@curtin-dxp/ui";

export const LanguageSelect = (props) => {
    return (
        <Dropdown id={props.id} label={props.label} handleSelection={(e) => props.handleSelection(e, props.id)} options={LanguageSelectOptions()} selection={({value:props.selection})} required={props.required} />        
    );
}

const LanguageSelectOptions = () => {
    const languages = [
        "English",
        "Sinhala",
        "Tamil",
        "Vietnamese",        
        "Albanian",
        "Amharic",
        "Arabic",
        "Azerbaijani",
        "Basque",
        "Belarusian",
        "Bengali",
        "Bhojpuri",
        "Bulgarian",
        "Burmese",
        "Catalan",
        "Cantonese Chinese",
        "Chichewa",
        "Cherokee",
        "Chittagonian",
        "Croatian",
        "Dutch",
        "Estonian",
        "Filipino (Tagalog)",
        "Finnish",
        "French",
        "Fula",
        "Gan Chinese",
        "German",
        "Greek",
        "Hawaiian",
        "Hebrew",
        "Hindi",
        "Hungarian",
        "Igbo",
        "Italian",
        "Japanese",
        "Javanese",
        "Kazakh",
        "Khmer",
        "Kinyarwanda",
        "Korean",
        "Kurdish",
        "Latvian",
        "Lithuanian",
        "Macedonian",
        "Malay",
        "Malayalam",
        "Maithili",
        "Marathi",
        "Mongolian",
        "Nepali",
        "Norwegian",
        "Oromo",
        "Pashto",
        "Polish",
        "Portuguese",
        "Punjabi",
        "Romanian",
        "Russian",
        "Serbian",
        "Sinhalese",
        "Slovak",
        "Slovenian",
        "Somali",
        "Spanish",
        "Swahili",
        "Swedish",
        "Telugu",
        "Thai",
        "Turkish",
        "Turkmen",
        "Ukrainian",
        "Urdu",
        "Uzbek",
        "Wu Chinese",
        "Xiang Chinese",
        "Yoruba",
        "Zulu"
    ];
    
    return (
        languages.map((lang) => (
            {
                value: lang
            }
        ))
    );
}