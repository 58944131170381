import styled from "styled-components";
import { Colour } from "@curtin-dxp/ui";

export const CompleteContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin: 5rem 0;
`;

export const CompleteInner = styled.div`
    width: 80%;
    min-width: 400px;
    max-width: 900px;
    border: 1px solid ${Colour.secondary.monochromeShades["grey-06"]};
    padding: 3rem;
    background-color: ${Colour.secondary.monochromeShades.white};

    display: flex;
    
    & h1 {
        color: ${Colour.primary.deepblue[400]};
        font-weight: 600;
    }
`;

export const CompleteImage = styled.img`
    padding: 0 2rem 0 0;
    max-height: 100px;
`;