import { FormRow, TextInput } from "@curtin-dxp/ui";
import * as CS from "../../Common/styles";

export const EmailPhoneDetails = (props) => {
    return (
        <CS.Section>
            <CS.H3>Email & Phone Details</CS.H3>
            <p>
                Please provide your current contact email and phone details (you must provide at least one phone number). If you are entering an international phone number, please include the full phone number along with the country code. Country code should include a "+". Your email address will be used to contact you with your Curtin ID and instructions to add or change your personal email address, update your details through your contact channel
            </p>
            <FormRow>
                <TextInput id="emailAddress" onChange={props.onChange} value={props.emailAddress} label="Email Address" required maxLength={255} />
            </FormRow>
            <FormRow>
                <TextInput id="confirmEmailAddress" onChange={props.onChange} value={props.confirmEmailAddress} label="Confirm Email address" required maxLength={255} />
            </FormRow>
            <FormRow>
                <TextInput id="phoneNumber" onChange={props.onChange} value={props.phoneNumber} label="Phone Number" required maxLength={20} />
            </FormRow>
        </CS.Section>
    );
}