import { DocumentUpload } from "./DocumentUpload";
import * as CS from "../../Common/styles";

export const DocumentUploads = (props) => {
    return (
        <CS.UploadsSection>
            <CS.H3>Upload Documents</CS.H3>
            <CS.InfoPanel>
                To ensure that your documents are processed by our system, <CS.Bold>please ensure <CS.Underline>each attachment</CS.Underline> does not exceed 10MB and the <CS.Underline>total of all attachments</CS.Underline> in your application does not exceed 100MB</CS.Bold>. Any attachments beyond the allowed size will not be delivered.                
            </CS.InfoPanel>
            <DocumentUpload id="filePassport" fileClass="Passport" label="Passport / Official Government-issued ID" description="Please upload a scanned copy of your passport or an official government-issued ID." handleFileUploaded={props.handleFileUploaded} applicationId={props.applicationId} onChange={props.onChange} handleRemoveFile={props.handleRemoveFile} handleSetFileUploadStatus={props.handleSetFileUploadStatus} comments={props.filePassportComments} commentsId="filePassportComments" originalFilename={props.filePassportOF} fileSize={props.filePassportFS} uploadStatus={props.filePassportUS} required />
            <DocumentUpload id="fileEnglishProficiency" fileClass="EnglishProficiency" label="English Language Proficiency" description="All applicants must meet Curtin's English language requirements such as English test results (i.e. IELTS)." handleFileUploaded={props.handleFileUploaded} applicationId={props.applicationId} onChange={props.onChange} handleRemoveFile={props.handleRemoveFile} handleSetFileUploadStatus={props.handleSetFileUploadStatus} comments={props.fileEnglishProfComments} commentsId="fileEnglishProficiencyComments" originalFilename={props.fileEnglishProficiencyOF} fileSize={props.fileEnglishProficiencyFS} uploadStatus={props.fileEnglishProficiencyUS} />
            {props.campus === "SLIIT" && (
                <DocumentUpload id="fileAcademicTranscripts" fileClass="AcademicTranscripts" label="Academic Transcripts" description="All applicants must provide their previous or current transcripts to facilitate a streamlined admission process. If you have yet to complete your program, you can attach a copy of your previous semester transcript and/or proof of your current enrolment in the course." handleFileUploaded={props.handleFileUploaded} applicationId={props.applicationId} onChange={props.onChange} handleRemoveFile={props.handleRemoveFile} handleSetFileUploadStatus={props.handleSetFileUploadStatus} comments={props.fileAcademicTranscriptsComments} commentsId="fileAcademicTranscriptsComments" originalFilename={props.fileAcademicTranscriptsOF} fileSize={props.fileAcademicTranscriptsFS} uploadStatus={props.fileAcademicTranscriptsUS} required />
            )}            
            {props.campus === "SEAMEO" && (
                <>
                    <DocumentUpload id="fileBachelorTranscriptsOrigLang" fileClass="BachelorTranscriptsOrigLang" label="Academic Transcripts in original language" handleFileUploaded={props.handleFileUploaded} applicationId={props.applicationId} onChange={props.onChange} handleRemoveFile={props.handleRemoveFile} handleSetFileUploadStatus={props.handleSetFileUploadStatus} comments={props.fileBachelorTranscriptsOrigLangComments} commentsId="fileBachelorTranscriptsOrigLangComments" originalFilename={props.fileBachelorTranscriptsOrigLangOF} fileSize={props.fileBachelorTranscriptsOrigLangFS} uploadStatus={props.fileBachelorTranscriptsOrigLangUS} required />
                    <DocumentUpload id="fileBachelorTranscriptsEnglish" fileClass="BachelorTranscriptsEnglish" label="Academic Transcripts in English translation" handleFileUploaded={props.handleFileUploaded} applicationId={props.applicationId} onChange={props.onChange} handleRemoveFile={props.handleRemoveFile} handleSetFileUploadStatus={props.handleSetFileUploadStatus} comments={props.fileBachelorTranscriptsEnglishComments} commentsId="fileBachelorTranscriptsEnglishComments" originalFilename={props.fileBachelorTranscriptsEnglishOF} fileSize={props.fileBachelorTranscriptsEnglishFS} uploadStatus={props.fileBachelorTranscriptsEnglishUS} />
                    <DocumentUpload id="fileAllDegreeAwardCertsOrigLang" fileClass="AllDegreeAwardCertsOrigLang" label="All degree award certificates in original language" handleFileUploaded={props.handleFileUploaded} applicationId={props.applicationId} onChange={props.onChange} handleRemoveFile={props.handleRemoveFile} handleSetFileUploadStatus={props.handleSetFileUploadStatus} comments={props.fileAllDegreeAwardCertsOrigLangComments} commentsId="fileAllDegreeAwardCertsOrigLangComments" originalFilename={props.fileAllDegreeAwardCertsOrigLangOF} fileSize={props.fileAllDegreeAwardCertsOrigLangFS} uploadStatus={props.fileAllDegreeAwardCertsOrigLangUS} required />
                    <DocumentUpload id="fileAllDegreeAwardCertsEnglish" fileClass="AllDegreeAwardCertsEnglish" label="All degree award certificates in English translation" handleFileUploaded={props.handleFileUploaded} applicationId={props.applicationId} onChange={props.onChange} handleRemoveFile={props.handleRemoveFile} handleSetFileUploadStatus={props.handleSetFileUploadStatus} comments={props.fileAllDegreeAwardCertsEnglishComments} commentsId="fileAllDegreeAwardCertsEnglishComments" originalFilename={props.fileAllDegreeAwardCertsEnglishOF} fileSize={props.fileAllDegreeAwardCertsEnglishFS} uploadStatus={props.fileAllDegreeAwardCertsEnglishUS} />
                    <DocumentUpload id="fileEmploymentLetters" fileClass="EmploymentLetters" label="Employment Letter/s detailing language teaching experience" handleFileUploaded={props.handleFileUploaded} applicationId={props.applicationId} onChange={props.onChange} handleRemoveFile={props.handleRemoveFile} handleSetFileUploadStatus={props.handleSetFileUploadStatus} comments={props.fileEmploymentLettersComments} commentsId="fileEmploymentLettersComments" originalFilename={props.fileEmploymentLettersOF} fileSize={props.fileEmploymentLettersFS} uploadStatus={props.fileEmploymentLettersUS} required />
                    <DocumentUpload id="fileCV" fileClass="CV" label="CV (Curriculum Vitae)" handleFileUploaded={props.handleFileUploaded} applicationId={props.applicationId} onChange={props.onChange} handleRemoveFile={props.handleRemoveFile} handleSetFileUploadStatus={props.handleSetFileUploadStatus} comments={props.fileCVComments} commentsId="fileCVComments" originalFilename={props.fileCVOF} fileSize={props.fileCVFS} uploadStatus={props.fileCVUS} required />
                    <DocumentUpload id="fileOtherSupportingMaterial" fileClass="OtherSupportingMaterial" label="Any other supporting material" style={({"border-bottom":"none","padding-bottom":0})} handleFileUploaded={props.handleFileUploaded} applicationId={props.applicationId} onChange={props.onChange} handleRemoveFile={props.handleRemoveFile} handleSetFileUploadStatus={props.handleSetFileUploadStatus} comments={props.fileOtherSupportingMaterialComments} commentsId="fileOtherSupportingMaterialComments" originalFilename={props.fileOtherSupportingMaterialOF} fileSize={props.fileOtherSupportingMaterialFS} uploadStatus={props.fileOtherSupportingMaterialUS} />
                </>
            )}
        </CS.UploadsSection>
    );
}