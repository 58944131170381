import { Dropdown } from "@curtin-dxp/ui";

export const CourseYearSelect = (props) => {
    const { handleSelection } = props;

    return (
        <Dropdown id={props.id} label={props.label} handleSelection={(e) => handleSelection(e, props.id)} selection={({ value: props.selection })} options={CourseYears()} required={props.required} />
    );
}

function CourseYears() {
    const yearsArray = ["2024", "2025", "2026"];

    return (
        yearsArray.map((year) => (
            {
                value: year
            }
        ))
    );
}