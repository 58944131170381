import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ApplicationComplete from './Pages/ApplicationComplete';
import ProvideDetails from './Pages/ProvideDetails';
import SubmitApplication from "./Pages/SubmitApplication";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/ProvideDetails' element={<ProvideDetails />} />
        <Route path='/SubmitApplication' element={<SubmitApplication />} />
        <Route path='/ApplicationComplete' element={<ApplicationComplete /> } />
        <Route index element={<Navigate to="/ProvideDetails" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;