import { useLocation } from "react-router-dom";
import * as S from "./styles";

export const ProgressTracker = (props) => {
    const location = useLocation();

    return (
        <S.ProgressTrackerContainer>
            <S.ProgressItem>
                <S.ProgressItemLabel currentstep={(location.pathname === '/ProvideDetails').toString()}>Provide Details</S.ProgressItemLabel>
            </S.ProgressItem>
            <S.ProgressSep />
            <S.ProgressItem>
                <S.ProgressItemLabel currentstep={(location.pathname === '/SubmitApplication').toString()}>Submit Application</S.ProgressItemLabel>
            </S.ProgressItem>
            <S.ProgressSep />
            <S.ProgressItem>
                <S.ProgressItemLabel currentstep={(location.pathname === '/ApplicationComplete').toString()}>Complete</S.ProgressItemLabel>
            </S.ProgressItem>
        </S.ProgressTrackerContainer>
    );
}