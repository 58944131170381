import { FormRow, TextInput } from "@curtin-dxp/ui";
import { CountrySelect } from "../../Common";
import * as CS from "../../Common/styles";

export const AddressDetails = (props) => {
    return (
        <CS.Section>
            <CS.H3>Address Details</CS.H3>
            <p>
                Please provide your current contact address details.
            </p>
            <FormRow>
                <CountrySelect id='addressCountry' label="Country" handleSelection={props.handleSelection} selection={props.addressCountry} ExcludeAustralia required />
            </FormRow>
            <FormRow>
                <TextInput id="addressLine1" label="Address Line 1" onChange={props.onChange} value={props.addressLine1} required maxLength={50} />
            </FormRow>
            <FormRow>
                <TextInput id="addressLine2" label="Address Line 2" onChange={props.onChange} value={props.addressLine2} maxLength={50} />
            </FormRow>
            <FormRow>
                <TextInput id="addressLine3" label="Address Line 3" onChange={props.onChange} value={props.addressLine3} maxLength={50} />
            </FormRow>
            <FormRow>
                <TextInput id="addressSuburbTown" label="Suburb/Town" onChange={props.onChange} value={props.addressSuburbTown} required maxLength={50} />
            </FormRow>
            <FormRow>
                <TextInput id="addressState" label="State" onChange={props.onChange} value={props.addressState} maxLength={50} />
            </FormRow>
            <FormRow>
                <TextInput id="addressPostcode" label="Postcode" onChange={props.onChange} value={props.addressPostcode} maxLength={12} />
            </FormRow>                                                                        
        </CS.Section>
    );
}