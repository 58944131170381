import React from "react";
import * as CS from "../../Common/styles";

export const TermsOfApplication = () => {
    return (
        <>
            <CS.H3>Applicant Declaration</CS.H3>
            <CS.UL>
                <li>I agree to be bound by the <a href="https://policies.curtin.edu.au/legislation/statutes_rules.cfm" target="_blank" rel="noreferrer">Statutes, Rules, By-Laws, Policies and Procedures</a> of the University as amended from time to time.</li>
                <li>I declare that I have read the instructions on this application and that the information I have provided is accurate and complete.</li>
                <li>I will inform the University immediately if any of the information provided changes.</li>
                <li>I understand that my application will be assessed based on Academic, Non-Academic, English and specific course requirements.</li>
                <li>I acknowledge that if I do not provide the requested information, Curtin University may not be able to assess my application for admission.</li>
                <li>I authorise Curtin University to obtain further academic information or official student records from any educational institution or recognised educational qualifications assessment body as necessary.</li>
                <li>I authorise Curtin University to verify my employment history for the purpose of making an informed decision about my application where my work experience is relevant.</li>
                <li>I understand that Curtin University is not responsible if any educational body or institution does not supply my academic or work history records.</li>
                <li>I acknowledge that Curtin University reserves the right to vary or reverse any decision regarding admission or academic progress made on the basis of incorrect, incomplete or fraudulent information.</li>
                <li>I understand that Curtin University may offer me the next best alternative (when appropriate) should I be unsuccessful in obtaining an offer for my desired course.</li>
                <li>I confirm that I have made my own research and enquiries as to the suitability of the course that I am seeking to be enrolled in.</li>
                <li>I understand that it is my responsibility to ensure that the information on my Curtin University offer letter is accurate.</li>
                <li>I acknowledge that the documents submitted with my application become the property of the University and will not be returned to me.</li>
                <li>I understand that the personal information collected on this form will be used in accordance with the Collection Statement (Privacy).</li>

            </CS.UL>
            
            <CS.H3>Collection Statement (Privacy)</CS.H3>
            <CS.UL>
                    <li>I acknowledge that information collected on this form and any further personal information provided during my enrolment at Curtin University will be kept on record and may be used or disclosed when accessing my application for entry into this course, for the purposes of providing me with information and services related to my enrolment and studies at Curtin University and that my personal information may also be disclosed to:
                        <CS.ULDots>
                            <li>
                                the Australian Government and designated authorities where required for compliance with the Education Services for Overseas Students Act 2000 (Cth), its associated legislation and any other applicable legislation where authorised by law to do so; and                                
                            </li>
                            <li>
                                any other relevant bodies for verification and assessment and/or Curtin overseas representatives for the purposes of assisting me with my application.                                
                            </li>
                        </CS.ULDots>
                    </li>     
                    <li>
                        I agree that Curtin University may collect and disclose personal information about me for the purposes of assessing my application, creating a record on its student database, undertaking statistical analysis, providing data to Government Departments as required by law, for verification of study loads, for remission of debt and to further inform me about relevant courses and events.                        
                    </li>    
                    <li>
                        I agree that any personal information collected by Curtin University about me will be retained as a University Record in accordance with the State Records Act (WA) and will be stored on systems of the Curtain Digital Factory, including services of Amazon Web Services (AWS) and Student One (S1) Student Management database and any other relevant systems from time to time.                    
                    </li>
                    <li>
                        I agree that any personal information collected by Curtin University about me will be retained as a University Record in accordance with the State Records Act (WA) in accordance with the university’s Information Management Policy and approved retention and disposal schedules.                        
                    </li>
                    <li>
                        I acknowledge that I have the right to access and amend personal information which Curtin University holds about me, subject to exceptions in relevant privacy legislation. To access personal information, contact the Privacy Officer at <a href="mailto:privacy@curtin.edu.au">privacy@curtin.edu.au</a>
                    </li>
                    <li>
                        I understand that I can obtain further information about privacy at Curtin University at <a href="https://informationmanagement.curtin.edu.au/privacy/" target="_blank" rel="noreferrer">https://informationmanagement.curtin.edu.au/privacy/</a>
                    </li>
            </CS.UL>

            [The following section only applies if the person submitting this application is not the applicant]<br />
            Agents of the university including external partner staff through the Curtin Transnational Partnership and Curtin’s TNE staff declare that:
            <CS.UL>
                <li>
                    I certify that I am an authorised representative for Curtin University and have been authorised to act on behalf of this applicant. I have made the applicant aware of their requirements within this Terms of Application (including the Collection Statement within) and have a signed declaration from the individual to demonstrate this has been provided to the applicant and they have acknowledged consent to provide their personal information.
                </li>
            </CS.UL>   
        </>
    );
}