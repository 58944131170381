import { Dropdown } from "@curtin-dxp/ui";

export const GenderSelect = (props) => {
    return (
        <Dropdown id={props.id} handleSelection={(e) => props.handleSelection(e, props.id)} selection={({value:props.selection})} label={props.label} options={genders()} required={props.required} />
    );    
}

function genders() {
    const genders = ["Female", 'Male', 'Indeterminate', 'Intersex', 'Unspecified'];    
    return (
        genders.map((option) => (
            {
                value: option
            }
        ))
    );
}