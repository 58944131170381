import { Button, Colour, Device, Icon, Link, Logo } from '@curtin-dxp/ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { rem } from 'polished';

export const HeaderContainer = styled(motion.header)`
  grid-area: header;
  display: flex;
  min-height: ${({ $open }) => ($open ? '100vh' : '6.5vh')};
  background: white;
  justify-content: space-between;
  min-width: 100%;
  overflow-x: hidden;
  transition: min-height 0.3s ease;
  align-items: center;
  z-index: 2;
`;

export const Container = styled.div`
  display: flex;
  align-self: flex-start;
  height: 6.5vh;
  width: 100%;
  justify-content: space-between;
  padding-left: ${rem(20)};
  align-items: center;
  overflow: hidden;
  transition: padding-left 0.15s ease;

  @media ${Device.MobileLg} {
    padding: 0 ${rem(30)};
  }
`;

export const HamburgerIcon = styled(Icon)``;

export const HamburgerButton = styled(Button)`
  display: flex;
  padding: 0.5rem;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 0;
  background-color: ${({ open }) => (open ? Colour.primary.deepblue[500] : 'transparent')};

  &:hover,
  &:focus {
    background-color: ${Colour.primary.deepblue[500]};
    ${HamburgerIcon} path {
      fill: white;
      stroke: white;
    }
  }
  &:active {
    background-color: ${Colour.primary.deepblue[400]};
  }

  @media ${Device.MobileLg} {
    display: none;
  }

  @media print {
    display: none;
  }
`;

export const CurtinLogo = styled(Logo)`
  max-width: ${rem(196)};

  &:focus-visible {
    outline: 2px solid #015ecc;
    outline-offset: 3px;
    border-radius: 2px;
  }
`;

export const Nav = styled.nav`
  position: fixed;

  pointer-events: ${({ $show }) => ($show ? 'all' : 'none')};
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};
  width: 100%;

  @media ${Device.MobileLg} {
    display: block;
    position: static;
    width: auto;
    padding-right: ${rem(30)};
  }

  @media print {
    display: none;
  }
`;

export const UnorderedList = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  width: 100vw;

  li:first-child {
    border-top: 0.5px solid ${Colour.secondary.monochromeShades['grey-06']};
  }

  @media ${Device.MobileLg} {
    flex-direction: row;
    position: static;
    width: auto;

    gap: ${rem(30)};

    li:first-child {
      border-top: none;
    }
  }
`;

export const ListItem = styled(motion.li)`
  list-style: none;
  border-bottom: 0.5px solid ${Colour.secondary.monochromeShades['grey-06']};
  padding: 0 ${rem(20)};

  @media ${Device.MobileLg} {
    border-bottom: none;
  }
`;

export const HeaderLink = styled(Link)`
  display: block;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  line-height: ${rem(60)};
  white-space: nowrap;

  &:focus-visible {
    outline: 2px solid #015ecc;
    outline-offset: 3px;
    border-radius: 2px;
  }

  @media ${Device.MobileLg} {
    line-height: unset;
  }
`;