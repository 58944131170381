import { Dropdown } from "@curtin-dxp/ui";

export const TitleSelect = (props) => {
    return (
        <Dropdown id={props.id} label={props.label} handleSelection={(e) => props.handleSelection(e, props.id)} options={titles()} selection={({ value: props.selection })} />
    );
}

function titles() {
    const titles = ["Dr", "Mr", "Ms", "Mrs", "Miss", "Prof", "Fr", "Br", "Sr", "Rev", "Haji", "Hajah", "Datuk", "Lady", "Mx"];
    return (
        titles.map((option) => ({
            value: option
        }))
    );
}