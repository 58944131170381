import styled from "styled-components";
import { Colour } from "@curtin-dxp/ui";

export const OtherCampus = styled.div`
    text-align: right;
    margin-top: 3rem;
    margin-right: -2rem;
    margin-bottom: -1.25rem;
    font-size: 1.3rem;

    & a {
        background-color: ${Colour.tertiary.chilli[400]};
        padding: 1rem 2rem;
        text-decoration: none;
        color: white;    
        margin: 0;
    }
`;