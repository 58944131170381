class ApplicationModel {
    constructor() {
        this.applicationId = '';
        this.correlationId = '';
        this.title = '';
        this.firstName = '';
        this.otherGivenNames = '';
        this.preferredGivenName = '';
        this.formalName = '';
        this.familyName = '';
        this.dob = '';
        this.dobDay = '';
        this.dobMonth = '';
        this.dobMonthName = '';
        this.dobYear = '';
        this.gender = '';
        this.emailAddress = '';
        this.confirmEmailAddress = '';
        this.phoneNumber = '';
        this.addressCountry = '';
        this.addressLine1 = '';
        this.addressLine2 = '';
        this.addressLine3 = '';
        this.addressSuburbTown = '';
        this.addressState = '';
        this.addressPostcode = '';
        this.countryOfCitizenship = '';
        this.countryOfBirth = '';
        this.mainLanguageHome = '';
        this.campus = '';
        this.seameoCourseYear = '';
        this.seameoTrimester = 'Trimester 2A';
        this.sliitCourse = '';
        this.sliitSpecialization = '';
        this.sliitALE = '';
        this.sliitALEALevel = '';
        this.sliitALEALevelSubject = '';
        this.sliitALEALevelOther = '';
        this.sliitALEOtherQualStudying = '';
        this.sliitCourseYear = '';
        this.sliitSemester = '';
        this.sliitACFP = '';
        this.filePassport = '';
        this.filePassportComments = '';
        this.filePassportOF = '';
        this.filePassportFS = '';
        this.filePassportUS = '';
        this.fileEnglishProficiency = '';
        this.fileEnglishProficiencyComments = '';
        this.fileEnglishProficiencyOF = '';
        this.fileEnglishProficiencyFS = '';
        this.fileEnglishProficiencyUS = '';
        this.fileAcademicTranscripts = '';    
        this.fileAcademicTranscriptsComments =  '';
        this.fileAcademicTranscriptsOF = '';
        this.fileAcademicTranscriptsFS = '';
        this.fileAcademicTranscriptsUS = '';
        this.fileBachelorTranscriptsOrigLang = '';        
        this.fileBachelorTranscriptsOrigLangComments = "";
        this.fileBachelorTranscriptsOrigLangOF = '';
        this.fileBachelorTranscriptsOrigLangFS = '';
        this.fileBachelorTranscriptsOrigLangUS = '';
        this.fileBachelorTranscriptsEnglish = '';
        this.fileBachelorTranscriptsEnglishComments = '';
        this.fileBachelorTranscriptsEnglishOF = '';
        this.fileBachelorTranscriptsEnglishFS = '';
        this.fileBachelorTranscriptsEnglishUS = '';
        this.fileAllDegreeAwardCertsOrigLang = '';
        this.fileAllDegreeAwardCertsOrigLangComments = '';
        this.fileAllDegreeAwardCertsOrigLangOF = '';
        this.fileAllDegreeAwardCertsOrigLangFS = '';
        this.fileAllDegreeAwardCertsOrigLangUS = '';
        this.fileAllDegreeAwardCertsEnglish = '';
        this.fileAllDegreeAwardCertsEnglishComments = '';
        this.fileAllDegreeAwardCertsEnglishOF = '';
        this.fileAllDegreeAwardCertsEnglishFS = '';
        this.fileAllDegreeAwardCertsEnglishUS = '';
        this.fileCV = '';
        this.fileCVComments = '';
        this.fileCVOF = '';
        this.fileCVFS = '';
        this.fileCVUS = '';
        this.fileEmploymentLetters = '';
        this.fileEmploymentLettersComments = '';
        this.fileEmploymentLettersOF = '';
        this.fileEmploymentLettersFS = '';
        this.fileEmploymentLettersUS = '';
        this.fileOtherSupportingMaterial = '';
        this.fileOtherSupportingMaterialComments = '';            
        this.fileOtherSupportingMaterialOF = '';
        this.fileOtherSupportingMaterialFS = '';
        this.fileOtherSupportingMaterialUS = '';
        this.additionalComments = '';
        this.message = '';
    }
}

export default ApplicationModel;