import * as S from "./styles";
import React, { useState, useCallback } from 'react';
import { useDropzone } from "react-dropzone";
import axios from "axios";
import UploadImage from "../../../../Images/upload.svg";
import SuccessImage from "../../../../Images/Flat_tick_icon.svg";
import RemoveImage from "../../../../Images/delete-button.svg";
import ErrorIcon from "../../../../Images/error.svg";

export const DocumentUpload = (props) => {
    const { applicationId, fileClass, handleFileUploaded, handleSetFileUploadStatus } = props;
    const [fileError, setFileError] = useState('');

    const onDrop = useCallback(async (acceptedFiles) => {
      setFileError('');

      if (acceptedFiles.length === 0) {
        setFileError('Please select a valid file type (jpg, jpeg, pdf, png, tif) and ensure it is not larger than 10MB.');
        handleSetFileUploadStatus(props.id, '');
        return;
      }

      const file = acceptedFiles[0];

      const allowedFileTypes = ["image/jpeg", "image/jpg", "application/pdf", "image/png", "image/tiff"];
      if (!allowedFileTypes.includes(file.type)) {
        setFileError('Invalid file type. Please select a file of type jpg, jpeg, pdf, png or tif.');
        handleSetFileUploadStatus(props.id, '');
        return;
      }

      const maxSizeBytes = 10 * 1024 * 1024;
      if (file.size > maxSizeBytes) {
        setFileError('File size exceeds the 10MB limit.');
        handleSetFileUploadStatus(props.id, '');
        return;
      }

      handleSetFileUploadStatus(props.id, 'Uploading');

      const fileNameParts = file.name.split('.');
      const fileExtension = fileNameParts[fileNameParts.length - 1];
      const lowerCaseFileExtension = fileExtension.toLowerCase();     
      const fileName = `${fileClass}.${lowerCaseFileExtension}`;

      try {
        const response = await axios.get(`/signed?fileName=${applicationId}/${fileName}`);

        if (response.status === 200) {
            await uploadFile(response.data.signed_url, file);
            handleFileUploaded(props.id, fileName, file.name, formatFileSize(file.size), "UploadComplete");
        } else {
          setFileError("An error occurred while uploading file");
        }
      } catch (error) {
        setFileError("An error occurred while uploading file");
        handleSetFileUploadStatus(props.id, '');
      }
    }, [applicationId, props.id, fileClass, handleFileUploaded]);
  
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: {  
        'image/png': ['.png'], 
        'image/jpeg': ['.jpg', '.jpeg'],
        'application/pdf': ['.pdf'],
        'image/tif': ['.tif']
      },
      maxSize: 10 * 1024 * 1024,
    });

    function formatFileSize(bytes) {
      const KB = 1024;
      const MB = 1024 * KB;
    
      if (bytes < KB) {
        return bytes + ' B';
      } else if (bytes < MB) {
        return (bytes / KB).toFixed(2) + ' KB';
      } else {
        return (bytes / MB).toFixed(2) + ' MB';
      }
    }    
  
    const uploadFile = async (url, file) => {
      if (!url) {
        setFileError("An error occurred while uploading file");
        handleSetFileUploadStatus(props.id, '');
        return;
      }

      if (!url) {
        setFileError("An error occurred while uploading file");
        handleSetFileUploadStatus(props.id, '');
        return;
      }

      try {
        const response = await axios.put(url, file, {
          mode: 'cors',
          headers: {
            'Content-Type': file.type,    
          },
        });
  
        if (response.status === 200) {
          handleSetFileUploadStatus(props.id, 'UploadComplete');
        } else {
          setFileError("An error occurred while uploading file");
          handleSetFileUploadStatus(props.id, '');
        }
      } catch (error) {
        setFileError("An error occurred while uploading file");
        handleSetFileUploadStatus(props.id, '');
      }
    };

    function handleRemoveFile(e) {
      e.preventDefault();
      e.stopPropagation();
      props.handleRemoveFile(props.id);
    }

    return (
        <S.FileUpload>
            <S.Strong>{props.label} {props.required && ("*")}</S.Strong>
            <h4>{props.description}</h4>

            <S.FileUploadContainer>
                <S.FileDropZone {...getRootProps()}>
                    {(!props.uploadStatus || props.uploadStatus === '') && (
                      <>
                        <S.UploadImage src={!fileError ? UploadImage : ErrorIcon} />
                        <input {...getInputProps()} />                        
                        <p style={({"color": fileError === '' ? "normal" : "red"})}>{fileError === '' ? "Drag & drop a file here, or click to select a file" : fileError}</p>
                      </>                      
                    )}
                    {props.uploadStatus === 'Uploading' && (
                      <>
                        <div className="spinner-border" role="status"></div>
                        Uploading...                      
                      </>
                    )}
                    {props.uploadStatus === "UploadComplete" && (
                      <>
                        <S.RemoveButtonContainer onClick={handleRemoveFile}>
                          <S.RemoveButton src={RemoveImage} alt="X" className="removeButton" title="Remove file" />           
                        </S.RemoveButtonContainer>
                        <S.UploadImage src={SuccessImage} alt="Upload Successful" />
                        Upload Complete
                      </>
                    )}
                </S.FileDropZone>
                <S.FileInfoComments>
                  <S.FileDetailsRow>                  
                    <S.FileDetails style={({"flex":1})}>
                      <S.InlineFlex style={({"margin-right":"1rem"})}>Filename:</S.InlineFlex>
                      <S.InlineFlex>{props.originalFilename}</S.InlineFlex>
                    </S.FileDetails>
                    <S.FileDetails>
                      <S.InlineStrong style={({"margin-right":"1rem"})}>Size:</S.InlineStrong>
                      <S.InlineFlex>{props.fileSize !== 0 ? props.fileSize : ''}</S.InlineFlex>
                    </S.FileDetails>
                  </S.FileDetailsRow>
                  <S.FileCommentsContainer>
                    <S.TextArea rows="3" placeholder="Add comments (if any)" id={props.commentsId} onChange={props.onChange} value={props.comments} />
                  </S.FileCommentsContainer>                  
                </S.FileInfoComments>            
            </S.FileUploadContainer>
              <S.UploadNote>
                Upload only one file. For multiple pages, please combine in PDF format.
              </S.UploadNote>                
        </S.FileUpload>
    );
}