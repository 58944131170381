import styled from "styled-components";
import { Colour } from "@curtin-dxp/ui";

export const FileUpload = styled.div`
    margin-top: 1rem;
    border-bottom: 1px solid ${Colour.secondary.monochromeShades['grey-07']};
    font-size: 1.2rem;
    padding-bottom: 1rem;
`;

export const FileUploadContainer = styled.div`
    display: flex;
    align-items: start;
    margin-top: 1rem;
`;

export const TextArea = styled.textarea`
    width: 100%;
    min-width: 400px;
    display: inline-flex;
    flex: 1;
    margin: 0 10px;
`;

export const FileDropZone = styled.div`
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding: 20px;
    cursor: pointer;
    height: 150px;
    width: 200px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    font-size: 1.2rem;

    && p {
        font-size: 1rem;
    }
`;

export const Strong = styled.div`
    font-weight: 600;
    margin-bottom: 0.75rem;
`;

export const FileInfoComments = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    && textarea {
        font-size: inherit;
        border: 2px solid #E8E8E8;
    }
`;

export const FileCommentsContainer = styled.div`
    display: flex;
    flex: 1;
`;

export const FileDetailsRow = styled.div`
    display: flex;
    padding-bottom: 0.6rem;
`;

export const FileDetails = styled.div`
    display: inline-flex;
    flex-direction: row;
    padding: 0 10px;
`;

export const InlineStrong = styled.strong`
    display: inline-flex;
`

export const InlineFlex = styled.div`
    display: inline-flex;

`
export const UploadImage = styled.img`
  height: 40px;
  width: 40px;
`;

export const RemoveButtonContainer = styled.div`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    margin: 0;
    padding: 0;
`;

export const RemoveButton = styled.img`
    height: 1.5rem;
    width: 1.5rem;
    opacity: 0.3;

    &:hover {
        opacity: 0.6;
    }
`;

export const UploadNote = styled.div`
    font-size: 0.8rem;
    padding-top: 0.5rem;
`;